import { authenticationConstants, underwriteDealConstants } from '../_constants'
import { authenticationService } from '../_services'
import { prospectUrl, createDeal } from '../_services/underwrite.service'
import { userActions, notificationsActions, businessActions, tenantActions, modalActions } from './'
import { setTimezone } from './getTimezone.actions'
import { AuthSubmit } from '../_components/modals/auth/AuthSubmit'
import {
  addTenantToMonitoringContext,
  addUserToMonitoringContext,
  removeUserFromMonitoringContext,
  history,
} from '../_helpers'
import { store } from '../_helpers/store'
import { isFeatureEnabled, isProductEnabled } from '../_helpers/flags'
import { AuthConfig } from '../_configs'
import ROUTES, { LENDER_ROUTES } from '../_constants/routes'
import { accountingActions } from './accounting.actions'
import { getABLBusiness } from '../_services/abl/abl-business.service'
import { FINANCING_TYPE_ABL } from '../_constants/financingTypes'
import { getSalesPersonByUserId } from '../_services/lender.service'
import { isAdminUser, isOperationsUser } from '../_helpers/user-utils'
import { getAuthorizations } from '../_services/authorization.service'
import { createInternalLinkUrl, searchParam } from 'xen/helpers'

export const authenticationActions = {
  signUp,
  signIn,
  validateSession,
  swapSession,
  signOut,
  forgotPassword,
  resetPassword,
  clearError,
  stopLoading,
  setViaProspectLink,
  signUpOnUnderwriting,
  getSignUpFormAndMarketing,
  signUpWithDealInfo,
}

function signUpOnUnderwriting(credentials, tenant) {
  const {
    email,
    password,
    confirm_password,
    temp_password,
    terms_accepted,
    business_phone_number,
    business_name,
    full_name,
    business_website,
    ...customFields
  } = credentials

  return async (dispatch) => {
    dispatch(
      request({
        email: email,
        business_phone_number: business_phone_number,
        business_name: business_name,
        full_name: full_name,
        business_website: business_website,
      })
    )

    try {
      const payload = {
        phone_number: business_phone_number,
        email: email,
        password: password,
        terms_accepted: terms_accepted,
        ...(Object.keys(customFields).length && { custom_fields: customFields }),
      }
      const headers = [{ 'tenant-token': tenant }]
      const response = await createDeal(payload, headers)
      const url = new URL(response.application_url)
      const urlParams = new URLSearchParams(url.search)
      const sessionToken = urlParams.get('identifier')
      await Promise.all([
        dispatch(userActions.getUser(sessionToken)),
        dispatch(success(sessionToken)),
        dispatch(complete()),
        dispatch(dealLoggedIn({ applicationURL: response.application_url })),
      ])
      const redirectPath = createInternalLinkUrl({ to: '/prospect-home' })
      // HACK: Get all latest data from <App>, using location.assign instead of
      // history.push. Some global app data is only fetched when logged in
      // because the router is nested inside app instead of the other way
      // around, so a client-side redirect won't re-render <App>.
      window.location.assign(redirectPath)
    } catch (error) {
      return dispatch(failure(error))
    }
  }

  function success(sessionToken) {
    return { type: authenticationConstants.SIGN_UP_SUCCESS, sessionToken }
  }
  function complete() {
    return { type: authenticationConstants.SIGN_UP_COMPLETE }
  }

  function dealLoggedIn(loggedDeal) {
    return { type: underwriteDealConstants.SET_PROSPECT_URL, loggedDeal }
  }

  function request(credentials) {
    return { type: authenticationConstants.SIGN_UP_REQUEST, credentials }
  }
  function failure(error) {
    return { type: authenticationConstants.SIGN_UP_FAILURE, error }
  }
}

function signUp(credentials, tenant, reCaptchaToken) {
  return (dispatch) => {
    dispatch(
      request({
        email: credentials.email,
        business_phone_number: credentials.business_phone_number,
        business_name: credentials.business_name,
        full_name: credentials.full_name,
        business_website: credentials.business_website,
      })
    )

    return authenticationService
      .signUp(credentials, reCaptchaToken)
      .then(async (resp) => {
        const sessionToken = resp.session_token
        const redirectPath = AuthConfig[tenant].sign_up.redirect || ROUTES.confirm_form

        dispatch(success(sessionToken))

        await Promise.all([
          dispatch(userActions.setUser(resp.user)),
          dispatch(businessActions.setBusiness(resp.business), isOperateEnabled()),
          dispatch(tenantActions.setTenant(resp.tenant)),
          dispatch(setTimezone(resp.tenant.tenant_timezone)),
        ])

        dispatch(complete())
        if (!AuthConfig[tenant].modal_body) {
          history.push(redirectPath)
        }
        dispatch(
          notificationsActions.showNotification({
            type: `success`,
            message: `Signed Up Successfully!`,
            id: `sign-up-success`,
          })
        )
        if (AuthConfig[tenant].modal_body) {
          dispatch(modalActions.openModal(AuthSubmit))
        }
        return resp.user
      })
      .catch((error) => dispatch(failure(error)))
  }

  function request(credentials) {
    return { type: authenticationConstants.SIGN_UP_REQUEST, credentials }
  }
  function success(sessionToken) {
    return { type: authenticationConstants.SIGN_UP_SUCCESS, sessionToken }
  }
  function complete() {
    return { type: authenticationConstants.SIGN_UP_COMPLETE }
  }
  function failure(error) {
    return { type: authenticationConstants.SIGN_UP_FAILURE, error }
  }
}

const dispatchSignInClient = async (dispatch, business, resp, financingType) => {
  const isAbl = financingType === FINANCING_TYPE_ABL

  if (business) {
    dispatch(businessActions.setBusiness(business, isOperateEnabled(), financingType))
  }

  if (!isAbl) {
    await dispatch(accountingActions.setAccounting(resp.accounting_connected, resp.accounting_platform))
  }
}

const dispatchSignIn = async ({ dispatch, resp, success, complete, sessionToken = null }) => {
  const user = resp.user
  const tenant = resp.tenant
  const isLender = user.user_type === 'lender'
  const isSalesPerson = user?.is_sales_person
  const financingType = resp.financing_type
  const isAbl = financingType === FINANCING_TYPE_ABL
  let business = null

  addUserToMonitoringContext(user)
  addTenantToMonitoringContext(tenant)

  await dispatch(success(sessionToken || resp.session_token, resp.access_token))
  dispatch(tenantActions.setTenant(tenant))

  if (!isLender) {
    business = isAbl ? await getABLBusiness(resp.user.business_abl_id) : resp.business
  }

  let redirectPath = isLender
    ? getLenderRedirectLink(resp.user)
    : await getClientRedirectLink(tenant, financingType, business, resp?.accounting_connected, resp?.deal_status)

  if (!isLender) {
    await dispatchSignInClient(dispatch, business, resp, financingType)
  }

  if (isSalesPerson) {
    await getSalesPersonByUserId(user.id).then((salesPerson) => {
      user.sales_person_id = salesPerson.id
    })
  }

  // Waiting for business and user to be set before redirecting
  await Promise.all([dispatch(userActions.setUser(resp.user)), dispatch(setTimezone(tenant.tenant_timezone))])

  function dealLoggedIn(loggedDeal) {
    return { type: underwriteDealConstants.SET_PROSPECT_URL, loggedDeal }
  }

  const isClient = isOperateEnabled() && business?.is_client

  await dispatch(complete())
  if (resp?.user?.underwrite_deal_id && !isClient) {
    // TODO: Should we wait for this to finish before redirecting?
    const newUnderwriteUrl = await newUnderwriteClientUrl()
    dispatch(dealLoggedIn({ applicationURL: newUnderwriteUrl }))
    redirectPath = createInternalLinkUrl({ to: '/prospect-home' })
  }

  const redirectTo = searchParam.get('redirectTo')

  // HACK: Get all latest data from <App>, using location.assign instead of
  // history.push. Some global app data is only fetched when logged in
  // because the router is nested inside app instead of the other way
  // around, so a client-side redirect won't re-render <App>.
  window.location.assign(redirectTo || redirectPath)

  dispatch(
    notificationsActions.showNotification({
      type: `success`,
      message: `Signed In Successfully!`,
      id: `sign-in-success`,
    })
  )
}

const isOperateEnabled = () => {
  const reduxState = store.getState()
  const featureFlags = reduxState?.featureFlags
  return isProductEnabled('Operate', featureFlags) && isProductEnabled('Factoring', featureFlags)
}

export const newUnderwriteClientUrl = async () => {
  const sessionToken = store.getState().authentication.sessionToken
  const response = await prospectUrl(sessionToken)
  return response.application_url
}

const getClientRedirectLink = async (
  tenant,
  financingType,
  business = false,
  accounting_connected = false,
  deal_status = 'pending'
) => {
  const isAbl = financingType === FINANCING_TYPE_ABL

  if (business?.is_client && (isOperateEnabled() || isAbl)) {
    return ROUTES.client_home
  } else if (business?.is_client && !isOperateEnabled()) {
    return ROUTES.submitted
  }
  const dealRoute = ROUTES.confirm_form

  return accounting_connected ? ROUTES.your_invoices : AuthConfig[tenant]?.sign_in?.redirect || dealRoute
}

export const getLenderRedirectLink = (user) => {
  const authResources = getAuthorizations(user)

  if (isAdminUser(user) && authResources['lender_home'] === true) {
    return ROUTES.lender_home
  }
  if (isOperationsUser(user)) {
    return LENDER_ROUTES.clients_overview
  }
  return LENDER_ROUTES.prospects_overview
}

function signIn(username, password, tenant, financing_type) {
  return (dispatch) => {
    dispatch(request())

    return authenticationService
      .signIn(username, password, false, false, financing_type)
      .then(async (resp) => {
        await dispatchSignIn({ dispatch, resp, success, complete, dealLoggedIn })
        delete window.xenChunkError

        return resp.user
      })
      .catch((error) => dispatch(failure(error)))
  }

  function dealLoggedIn(loggedDeal) {
    return { type: underwriteDealConstants.SET_PROSPECT_URL, loggedDeal }
  }

  function request() {
    return { type: authenticationConstants.SIGN_IN_REQUEST }
  }
  function success(sessionToken, accessToken) {
    return { type: authenticationConstants.SIGN_IN_SUCCESS, sessionToken, accessToken }
  }
  function complete() {
    return { type: authenticationConstants.SIGN_IN_COMPLETE }
  }
  function failure(error) {
    return { type: authenticationConstants.SIGN_IN_FAILURE, error }
  }
}

function validateSession(sessionToken) {
  return (dispatch) => {
    dispatch(request())

    return authenticationService
      .validateSession(sessionToken)
      .then(async (resp) => {
        const user = resp.user

        if (user.is_admin === 1) {
          failWithMessage(dispatch, 'Session belongs to an admin user.')
          return Promise.reject()
        }

        await dispatchSignIn({ dispatch, resp, success, complete, sessionToken })

        return resp.user
      })
      .catch((_) => {
        failWithMessage(dispatch, 'Unable to validate session.')
      })
  }

  function request(credentials) {
    return { type: authenticationConstants.SIGN_IN_REQUEST, credentials }
  }
  function success(sessionToken, accessToken) {
    return { type: authenticationConstants.SIGN_IN_SUCCESS, sessionToken, accessToken }
  }
  function complete() {
    return { type: authenticationConstants.SIGN_IN_COMPLETE }
  }
  function failure(error) {
    return { type: authenticationConstants.SIGN_IN_FAILURE, error }
  }

  function failWithMessage(dispatch, message) {
    dispatch(failure(message))
    dispatch(
      notificationsActions.showNotification({
        type: `error`,
        message,
        id: `validate-session-failure`,
      })
    )
    history.push(ROUTES.sign_in)
  }
}

function swapSession(initialToken, newToken) {
  return (dispatch) =>
    dispatch(signOut(initialToken, true))
      .then((_) => dispatch(validateSession(newToken)))
      .catch((_) => {
        dispatch(
          notificationsActions.showNotification({
            type: `error`,
            message: `Unable to sign out`,
            id: `sign-out-failure`,
          })
        )
      })
}

function signOut(sessionToken, noRedirect = false, isIdleTimeout = false, idleTimeoutMessage = '') {
  return (dispatch) => {
    dispatch(request())

    return authenticationService
      .signOut(sessionToken)
      .then((_) => {
        dispatch(success({ isIdleTimeout, idleTimeoutMessage }))
        dispatch(
          notificationsActions.showNotification({
            type: `success`,
            message: `Signed Out Successfully!`,
            id: `sign-out-success`,
          })
        )
        removeUserFromMonitoringContext()
        delete window.xenChunkError
        const redirectPath = ROUTES.sign_in
        if (!noRedirect) history.push(redirectPath)
      })
      .catch((error) => {
        dispatch(failure(error))
        dispatch(
          notificationsActions.showNotification({
            type: `error`,
            message: `Unable to sign out`,
            id: `sign-out-failure`,
          })
        )
      })
  }

  function request() {
    return { type: authenticationConstants.SIGN_OUT_REQUEST }
  }
  function success({ isIdleTimeout, idleTimeoutMessage }) {
    return {
      type: authenticationConstants.SIGN_OUT_SUCCESS,
      isIdleTimeout,
      idleTimeoutMessage,
    }
  }
  function failure(error) {
    return { type: authenticationConstants.SIGN_OUT_FAILURE, error }
  }
}

function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request({ email }))

    return authenticationService
      .forgotPassword(email)
      .then((_) => {
        const redirectPath = ROUTES.reset_password

        dispatch(success())
        history.push(redirectPath)
        dispatch(
          notificationsActions.showNotification({
            type: `success`,
            message: `A link to reset your password has been sent to ${email}, please check your email!`,
            id: `forgot-password-success`,
          })
        )
      })
      .catch((_) => dispatch(failure(`Email not found.`)))
  }

  function request(credentials) {
    return { type: authenticationConstants.FORGOT_PASSWORD_REQUEST, credentials }
  }
  function success() {
    return { type: authenticationConstants.FORGOT_PASSWORD_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.FORGOT_PASSWORD_FAILURE, error }
  }
}

function getSignUpFormAndMarketing(tenantToken) {
  return (dispatch) => {
    dispatch(request())

    return authenticationService
      .getSignUpFormAndMarketing(tenantToken)
      .then((resp) => {
        dispatch(success(resp))
      })
      .catch((error) => {
        console.error('Error getting sign up form and marketing', error)
        return dispatch(failure(error))
      })
  }

  function request() {
    return { type: authenticationConstants.GET_SIGN_UP_FORM_AND_MARKETING_REQUEST }
  }
  function success(signUpAndMarketingForm) {
    return { type: authenticationConstants.GET_SIGN_UP_FORM_AND_MARKETING_SUCCESS, signUpAndMarketingForm }
  }
  function failure(error) {
    return { type: authenticationConstants.GET_SIGN_UP_FORM_AND_MARKETING_FAILURE, error }
  }
}

function resetPassword(email, tempPassword, newPassword, userid, sessionToken) {
  return (dispatch) => {
    dispatch(request({ email }))

    return authenticationService
      .resetPassword(email, tempPassword, newPassword, userid)
      .then((_) => {
        const redirectPath = ROUTES.sign_in

        dispatch(success())
        history.push(redirectPath)
        dispatch(
          notificationsActions.showNotification({
            type: `success`,
            message: `Password successfully updated!`,
            id: `reset-password-success`,
          })
        )
        if (sessionToken) {
          dispatch(signOut(sessionToken))
        }
      })
      .catch((_) => {
        dispatch(failure(`Invalid Credentials.`))
      })
  }

  function request(credentials) {
    return { type: authenticationConstants.RESET_PASSWORD_REQUEST, credentials }
  }
  function success() {
    return { type: authenticationConstants.RESET_PASSWORD_SUCCESS }
  }
  function failure(error) {
    return { type: authenticationConstants.RESET_PASSWORD_FAILURE, error }
  }
}

function clearError() {
  return {
    type: authenticationConstants.CLEAR_ERROR,
  }
}

function stopLoading() {
  return {
    type: authenticationConstants.STOP_LOADING,
  }
}

function setViaProspectLink() {
  return {
    type: authenticationConstants.VIA_PROSPECT_LINK,
  }
}

function signUpWithDealInfo(customPayload) {
  return async (dispatch) => {
    dispatch(request())

    try {
      const response = await authenticationService.signUpWithDealInfo(customPayload)
      const url = new URL(response.application_url)
      const urlParams = new URLSearchParams(url.search)
      const sessionToken = urlParams.get('identifier')
      await Promise.all([
        dispatch(userActions.getUser(sessionToken)),
        dispatch(success(sessionToken)),
        dispatch(complete()),
        dispatch(dealLoggedIn({ applicationURL: response.application_url })),
      ])
      const redirectPath = createInternalLinkUrl({ to: '/prospect-home' })
      // HACK: Get all latest data from <App>, using location.assign instead of
      // history.push. Some global app data is only fetched when logged in
      // because the router is nested inside app instead of the other way
      // around, so a client-side redirect won't re-render <App>.
      window.location.assign(redirectPath)
    } catch (error) {
      return dispatch(failure(error))
    }
  }

  function success(sessionToken) {
    return { type: authenticationConstants.SIGN_UP_SUCCESS, sessionToken }
  }
  function complete() {
    return { type: authenticationConstants.SIGN_UP_COMPLETE }
  }

  function dealLoggedIn(loggedDeal) {
    return { type: underwriteDealConstants.SET_PROSPECT_URL, loggedDeal }
  }

  function request() {
    return { type: authenticationConstants.SIGN_UP_REQUEST }
  }
  function failure(error) {
    return { type: authenticationConstants.SIGN_UP_FAILURE, error }
  }
}
